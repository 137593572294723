/* global jQuery*/

jQuery(function($) {
  'use strict';

  jQuery.validator.setDefaults({
    errorClass: 'has-error',
    validClass: 'has-success',
    errorElement: 'span',

    highlight: function(element, errorClass, validClass) {
      $(element).closest('.form-group').addClass(errorClass);
    },
    unhighlight: function(element, errorClass, validClass) {
      $(element).closest('.form-group').removeClass(errorClass);
    },
    errorPlacement: function(error, element) {
      if(element.parent('.input-group').length) {
        error.insertAfter(element.parent());
      }
      else {
        error.insertAfter(element);
      }
    }
  });

  $('#signinForm').validate();
});
